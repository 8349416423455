import axios from '@/common/axios'
import urls from '@/common/urls'
import config from '@/common/config'
import tips from '@/common/tips'
import html2canvas from 'html2canvas'
let cfg = {
  project: 'guzhuanyizhoukan',
  type: "home"
}
async function uploadFile(info) {
  console.log('object :>> ', info);
  info = {
    file: "",
    reg: 'jpg|jpeg|png|PNG|JPG',
    key: "file",
    ...info
  }
  if (!info.file) return;
  let RegObj = new RegExp(info.reg, 'i')
  let type = info.file.name.split('.').slice(-1)
  if (!RegObj.test(type)) {
    tips.error({ text: `上传文件类型必须是${info.reg.replace(/\|/g, ',')}` })
    return '';
  }
  let data = await requestFile(info)

  return data
}
async function requestFile(info) {
  console.log(info, info.url)
  let file = info.file,
    key = info.key || 'file',
    url = info.url || urls.uploads.uploadFile,
    headers = info.headers || {},
    item = { ...cfg, ...info.item }
  let data = new FormData()
  data.append(key, file)
  for (let i in item) {
    data.append(i, item[i])
  }
  return new Promise(resolve => {
    axios.post(url, data, headers).then(res => {
      resolve(res)
    }).catch(err => {
      resolve({ url: "" })
    })
  })
}

async function saveImg(dom) {
  return new Promise(resolve => {
    html2canvas(dom).then(canvas => {
      let file = canvas.toDataURL("image/png")
      resolve(file)
    });
  })
}
async function savePage(dom, headers) {
  let file = await saveImg(dom);
  let data = await requestFile({
    file, url: urls.uploads.uploadBase64, headers
  });
  return data.url;
}

async function uploadBase64(info) {
  console.log('info :>> ', info);
  let data = await requestFile({ url: urls.uploads.uploadBase64, ...info });
  return data.data;
}

export default {
  uploadFile,
  requestFile,
  uploadBase64,
  savePage
}